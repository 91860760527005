import React from "react";

function CA() {
  const style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
  };

  const buttonStyle = {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "white",
    textDecoration: "none",
    borderRadius: "4px",
    marginTop: "20px",
  };

  const h2style = {
	  	color: "white",
  }

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/ca.pem`;
    link.download = "ca.pem";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={style}>
      <h2 style={h2style}>A CA tanúsítvány letöltéséhez kattints a gombra:</h2>
      <a href={"#"} style={buttonStyle} onClick={handleDownload}>
        CA Letöltése
      </a>
    </div>
  );
}

export default CA;
